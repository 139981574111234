<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col cols="12" sm="10"> <h2>Company Profile</h2> </v-col>
    </v-row>
    <v-row>
      <v-col class="pr-3" cols="12" sm="6" lg="4">
        <v-text-field
          label="Business Name"
          v-model="company.name"
          validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <v-text-field label="Tagline" v-model="company.title"></v-text-field>
      </v-col>
    </v-row>

    <template v-if="company.id">
      <v-row>
        <v-col cols="12" lg="8">
          <v-textarea
            v-model="company.description"
            label="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-text-field
            label="Business Phone"
            prepend-icon="phone"
            v-model="company.phone"
            :hint="$t('Chef.chef_privacy_hint1')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <v-text-field
            label="Business Email"
            prepend-icon="email"
            v-model="company.email"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <v-text-field
            label="Website"
            prepend-icon="public"
            v-model="company.website"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" sm="5" lg="4" v-if="!showAddMember">
          <v-btn color="info" @click.stop="showAddMember = true" outlined
            >+ Invite new member</v-btn
          >
        </v-col>
        <v-col cols="2" v-if="!showAddMember">
          <v-btn icon @click.stop="showUsers = !showUsers"
            ><v-icon>group</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="showAddMember">
        <v-col cols="12" sm="3">
          <v-text-field
            label="Member Email"
            v-model="memberEmail"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <v-btn small color="success" @click="invite()">invite</v-btn
          ><v-btn small @click.stop="showAddMember = false">cancel</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showUsers">
        <v-col cols="12" sm="8">
          <v-list>
            <v-list-item v-for="(m, i) in company.users" :key="i">
              <v-list-item-content> {{ m.email }} </v-list-item-content>
              <v-list-item-avatar> </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-btn color="info" @click="addBusinessHour" outlined
            >+ Add Business Hours</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        align="center"
        v-for="(business_day, key) in business_days"
        :key="key"
      >
        <v-col cols="12" sm="5" lg="4">
          <v-select
            prepend-icon="calendar_today"
            multiple
            :items="[
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday'
            ]"
            v-model="business_days[key].business_day"
            label="Open days"
          ></v-select>
        </v-col>
        <v-col cols="6" sm="3" lg="2">
          <v-select
            :items="startHourList"
            v-model="business_days[key].fromHour"
            label="from"
          ></v-select>
        </v-col>
        <v-col cols="5" sm="3" lg="2">
          <v-select
            :items="endHourList"
            v-model="business_days[key].endHour"
            label="to"
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-btn text color="red" @click="removeHour(key)">
            <v-icon dark>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"> <h4>Business Address</h4> </v-col>
        <v-col cols="12" sm="3">
          <v-switch
            label="Use profile address"
            v-model="useProfileAddress"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row v-if="!useProfileAddress">
        <v-col cols="12"> <base-address></base-address> </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-row>
            <template v-for="(address, index) in profile.addresses">
              <v-col class="pr-1 pb-1" sm="12" lg="6" :key="index">
                <address-view
                  :address="address"
                  :hide_remove="true"
                  :index="index"
                ></address-view>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pr-2" cols="12" sm="6" md="4">
          <v-card @click="onShowUpload('logo')">
            <template v-if="logoFile">
              <v-img contain :src="logoFile.url" height="100" alt="" />
            </template>
            <template v-else-if="company.logo">
              <span class="small">Logo</span>
              <v-img contain :src="company.logo" height="200px" alt="" />
            </template>
            <template v-else>
              <v-img contain height="200px">
                <h4 class="grey--text font-weight-thin font-italic">
                  Company Logo.
                </h4>
                <h5 class="grey--text font-weight-thin font-italic">
                  Recommended size 400X400
                </h5>
              </v-img>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-card @click="onShowUpload('headerImage')">
            <template v-if="headerFile">
              <v-img :src="headerFile.url" alt="" />
            </template>
            <template v-else-if="company.headerImage">
              <span class="small">Header Image</span>
              <v-img :src="company.headerImage" alt="" />
            </template>
            <template v-else>
              <v-img contain height="200px">
                <h4 class="grey--text font-weight-thin font-italic">
                  Cover Image.
                </h4>
                <h5 class="grey--text font-weight-thin font-italic">
                  Recommended size 400X200
                </h5>
              </v-img>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn color="success" @click.once="save">{{ $t(actionName) }} </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="showUpload"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on }"> </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="showUpload = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Upload </v-toolbar-title>
          </v-toolbar>
          <file-upload
            @onFileUpload="onUpload"
            :imageClass="'box'"
            :viewMode="1"
            :type="imageType"
          ></file-upload>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { globalMixin } from 'fod-core'

export default {
  mixins: [globalMixin],

  components: {
    AddressView: () =>
      import('fod-core/src/components/profile/AddressView.vue'),
    BaseAddress: () =>
      import('fod-core/src/components/profile/BaseAddress.vue'),
    FileUpload: () => import('fod-core/src/components/common/FileUpload.vue')
  },
  data() {
    return {
      website: null,
      useProfileAddress: true,
      showUpload: false,
      logoFile: null,
      headerFile: null,
      imageType: null,
      showAddMember: false,
      showUsers: false,
      memberEmail: null,
      memberRole: 'user',
      business_days: [],
      startHourList: null,
      endHourList: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      company: 'company',
      user: 'user',
      profile: 'profile'
    }),
    valid() {
      return this.name
    },
    actionName() {
      return this.company.id ? 'Common.save' : 'Common.create'
    }
  },
  mounted() {
    this.$store.dispatch('getCompany')
    this.startHourList = this.eachHalfHour('00:00', `23:30`)
    this.endHourList = this.eachHalfHour(`00:00`, '23:30')
    this.updateInfo()
  },
  watch: {
    company() {
      this.updateInfo()
    }
  },
  methods: {
    save() {
      this.company.business_days = this.business_days
      this.$store.dispatch('saveCompany', this.company)
    },
    updateInfo() {
      if (this.company) {
        this.business_days = this.company.business_days || []
      }
    },
    onShowUpload(type) {
      this.showUpload = true
      this.imageType = type
    },
    onUpload(file) {
      console.log(`uploading ........${this.imageType}`, file)
      this.showUpload = false
      if (this.imageType === 'logo') {
        this.logoFile = file
      } else {
        this.headerFile = file
      }
      this.$store.dispatch('upload', {
        target: 'company',
        id: this.company.id,
        imageType: this.imageType,
        file: file
      })
    },
    invite() {
      //this.showAddMember = false

      this.$store
        .dispatch('invite', {
          sender: this.user.id,
          role: this.memberRole,
          type: 'member',
          to: this.memberEmail,
          //message: this.message,
          cid: this.company.id
        })
        .then(i => {
          console.log('888888888888 ', i)
        })
      /*
      this.$store.dispatch('companyInviteMember', {
        email: this.memberEmail,
        role: this.memberRole,
        status: 'new',
        createdTime: new Date().getTime(),
        cid: this.company.id
      })*/
    },
    addBusinessHour() {
      this.business_days.push({})
    },
    removeHour(key) {
      this.business_days.splice(key, 1)
    }
  }
}
</script>

<style></style>
